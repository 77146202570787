<template>
  <span
    class="k-icon k-i-loading loading-indicator"
  />
</template>

<script>
export default {
  name: 'AppLoadingIndicator'
}
</script>
